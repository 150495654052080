import React from 'react';
import '../assets/css/LandingPage.css';
import "../assets/css/overrides.css";
// import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './Contact';
import Home from './Home';
import Mood from './Mood';
import Quote from './Quote';
import Conversation from './Coversation';
import OffTheRecord from './OffTheRecord';
import Footer from './Footer';

class LandingPage extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
            destination:''
        };
    }

render(){
    const anchors = ["home", "quote", "mood","conversation","record","contact","privacy-policy"];
    const tooltips = ["Home", "Quote of the week", "Choose your mood","Encourage conversation","Off the record","Contact us","Privacy Policy"];

    return <div>
        <ReactFullpage
            anchors={anchors}
            navigation
            navigationTooltips={tooltips}
            autoScrolling={true}
            scrollHorizontally={true}
            onLeave={(origin, destination, direction) => {
                // console.log("Leaving section " + origin.index);
            }}
            afterLoad={(origin, destination, direction) =>{
                this.setState({destination:destination.index})
                // console.log("After load: " + destination.index);
              }}     
            render={({ state, fullpageApi }) => {
                // console.log(this.state.destination)
            return (
                <div>
                    <div className="section">
                        <Home/>
                    </div>
                    <div className="section">
                        <Quote/>
                    </div>
                    <div className="section">
                        <Mood/>
                    </div>
                    <div className="section">
                        <Conversation/>
                    </div>
                    <div className="section">
                        <OffTheRecord/>
                    </div>
                    <div className="section">                
                        <Contact/>
                    </div>
                    <div className="section">                
                        <PrivacyPolicy/>
                    </div>
                </div>
            );
            }}
        />

        <div id="infoMenu">
            <Footer/>
        </div>
        </div>
}
}
export default LandingPage;
