import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import '../assets/css/Mood.css';
import record from '../assets/images/record.png';

class OffTheRecord extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
        };
    }

render(){
    return <Container>
        <Row>
            <Col className="align_center">
                <p className="mood_title">
                    <strong>OFF THE RECORD</strong>
                </p>
                <p className="mood_content">
                    Conversation with users you've connected with.
                </p>
            </Col>        
            <Col>
                <img src={record} alt="record_bg" className="mood_img"/>
            </Col>
            </Row>
    </Container>;
}
}
export default OffTheRecord;
