import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import '../assets/css/Quote.css';
import quote_img from '../assets/images/quote.png';

class Quote extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
        };
    }

render(){
    return <Container>
        <Row className="full_width">
            <Col>
                <img src={quote_img} alt="quote_img" className="quote_img"/>
            </Col>
            <Col className="align_center">
                <p className="quote_title">
                    <strong>QUOTE OF THE WEEK</strong>
                </p>
                <p className="quote_content">
                    Why fit in when you were born to STANDOUT!
                </p>
            </Col>
        </Row>
    </Container>;
}
}
export default Quote;
