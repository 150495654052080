import axios from 'axios';
// import {PRODUCTION_API_BASE} from '../helpers/constant'
const LOCALURL = process.env.REACT_APP_API_URL;

export const ContactMailApi = (params) => {
    return new Promise(function(resolve, reject) { 
     axios.post(`${LOCALURL}/api/v1/auth/contact`, params)
          .then(
            function(res){
              if(res.data.status === 200){
                resolve(res.data)
              }
              else{
                console.log("Something went wrong!")
              }
            }
          )
    })
  };

export const GetPrivacyPolicyApi = (params) => {
    return new Promise(function(resolve, reject) { 
      // https://helpace.app:5357/api/master/pages/get/privacy-policy
     axios.get(`${LOCALURL}/api/master/pages/get/privacy-policy`)
          .then(
            function(res){
              if(res.data.status === 200){
                resolve(res.data)
              }
              else{
                console.log("Something went wrong!")
              }
            }
          )
    })
  };
