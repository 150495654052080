import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/css/Footer.css';
import insta from '../assets/images/instagram.png';
import fb from '../assets/images/facebook.png';
import apple from '../assets/images/apple.png';
import playstore from '../assets/images/play-store.png';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className="container_div">
            <div className="footer_content">
                <div>
                    <a href="#mood" className="follow_links_title">About App</a>|
                </div>
                <div>
                    <a href="#contact" className="follow_links_title">Contact Us</a>|
                </div>
                <div>
                    <a href="#privacy-policy" className="follow_links_title">Privacy Policy</a>|
                </div>
                <div>
                    <span className="follow_links_title">Follow us on</span>
                    <a href="https://www.instagram.com/helpace/?hl=en">
                        <img src={insta} alt="insta" className="follow_img" />
                    </a>
                    <a href="#home">
                        <img src={fb} alt="fb" className="follow_img" />
                    </a>
                </div>
            </div>
            <div className="footer_content">
                <div className="follow_links_title">Download App</div>
                <div className="no_margin">
                    <div>
                        <a id="remove_underline" href='https://apps.apple.com/us/app/helpace/id1504219513?ls=1' target='blank'>
                            <div className="bordered_box" id="margin_bottom">
                                <Row>
                                    <Col xs={5} md={5}>
                                        <img src={apple} alt="app-store" className="download_app" />
                                    </Col>
                                    <Col xs={7} md={7}>
                                        <Row><div className="download_on">Download On </div></Row>
                                        <Row><div className="store"><strong>App Store</strong></div> </Row>
                                    </Col>
                                </Row>
                            </div>
                        </a>
                    </div>
                </div>
                <div>
                <a id="remove_underline" href='https://play.google.com/store/apps/details?id=com.tanisha.helpACE' target='blank'>
                <div className="bordered_box" id="margin_bottom">
                    <Row>
                        <Col xs={5} md={5}>
                            <img src={playstore} alt="play-store" className="download_app" />
                        </Col>
                        <Col xs={7} md={7}>
                            <Row><div className="download_on">Get it On</div></Row>
                            <Row><div className="store"><strong>Google Play</strong></div> </Row>
                        </Col>
                    </Row>
                </div>
                </a>
                </div>
                 </div>
        </div>;
    }
}
export default Footer;
