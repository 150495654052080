import React from 'react';
import {Button, Row, Col, Container} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import '../assets/css/ContactForm.css';
import {ContactMailApi} from '../api/index';

class Contact extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
            email:'',
            message:'',
            name:'',
        };
         this.formRef = null;
        }
        
        handleSubmit(event) {
            event.preventDefault();
            let searchdata={};
            searchdata = {
                email:this.state.email ,
                message:this.state.message,
                name:this.state.name
            };
            ContactMailApi(searchdata).then(
                (res) => {
                    if(res) {
                        console.log("res",res)
                            if(res.status===200){
                                alert(res.message);
                                this.formRef.reset();
                            }
                            else{
                                alert("Something went wrong!");
                            }
                    }
                }
            )
            }

render(){
    return <Container>
        <Row className="full_width">
            <Col className="align_self" md={5} xs={0}>
                <p className="contact_title" id="align_center">
                    <strong>CONTACT US</strong>
                </p>
            </Col>
            <Col>
                <Form onSubmit={(e) => this.handleSubmit(e)}
                      ref={(ref) => this.formRef = ref} >
                    <Form.Group>
                        <Form.Control className="form_background" placeholder="Your Name" required type="text" onChange={(event) => {
                            this.setState({name: event.target.value})
                        }}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control className="form_background" placeholder="Your Email" required type="email" onChange={(event) => {
                            this.setState({email: event.target.value})
                        }}/>
                    </Form.Group>

                    <Form.Group>
                            <Form.Control className="form_background" placeholder="Your Message" as="textarea" rows="4" required
                                          onChange={(event) => {
                                              this.setState({message: event.target.value})
                            }}/>
                    </Form.Group>
                    <div className="para_space">
                        <p/>
                        <p/>
                        <p/>
                    </div>
                    <Button variant="primary" type="submit" className="submit_form">
                        Send
                    </Button>
                </Form>
            </Col>
        </Row>
    </Container>;
}
}
export default Contact;
