import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import '../assets/css/Mood.css';
import mood from '../assets/images/mood.png';

class Mood extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
        };
    }

render(){
    return <Container>
        <Row>
            <Col className="align_center">
                <p className="mood_title">
                    <strong>CHOOSE YOUR MOOD</strong>
                </p>
                <p className="mood_content">
                    Select the 
                        <span className="color_yellow"> A</span>
                        <span className="color_orange">C</span>
                        <span className="color_red">E</span>
                    oji that reflects your current mood and express yourself in your post.
                </p>
            </Col>
            <Col>
                <img src={mood} alt="mood_bg" className="mood_img"/>
            </Col>
        </Row>
    </Container>;
}
}
export default Mood;
