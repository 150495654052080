import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import '../assets/css/Quote.css';
import chat_bg from '../assets/images/chat.png';

class Conversation extends React.Component{
    constructor(props) {
        super(props); 
        this.state = { 
        };
    }

render(){
    return <Container>
        <Row className="full_width">
            <Col>
                <img src={chat_bg} alt="chat_bg" className="quote_img"/>
            </Col>
            <Col className="align_center">
                <p className="quote_title">
                    <strong>ENCOURAGE CONVERSATION</strong>
                </p>
                <p className="quote_content">
                    By connecting to those with whom you can relate and also with those with whom you can debate.
                </p>
            </Col>
        </Row>
    </Container>;
}
}
export default Conversation;
