import React from 'react';
import '../assets/css/Home.css';
import {GetPrivacyPolicyApi} from '../api/index';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
        };
    }

    componentDidMount() {
        GetPrivacyPolicyApi().then(response => {
                this.setState({
                    content: response.data.description,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return <div>
            <div className="static_title">
                PRIVACY POLICY
            </div>
            <div className="static_content">
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </div>
        </div>
    }
}
export default Home;
